import React from 'react';

import UI from '#front-credpago';

import DownloadItem from '../components/download-item';
import { DownloadsWrapper } from '../components/download-item/styles';
import Layout from '../components/layout/index';
import SEO from '../components/seo';
import ManualDaMarcaPreview from '../images/downloads/guia-de-marca.jpg';
import ManualRedacaoPreview from '../images/downloads/guia-de-redacao.jpg';

const downloads = [
  {
    name: 'Manual da marca v.01',
    directLink: '/downloads/Guias/Manual de Marca 2021 - CredPago_v_1.pdf',
    directLinkFormat: 'pdf',
    image: ManualDaMarcaPreview,
    imageFullSize: true,
  },
  {
    name: 'Manual de redação v.01',
    directLink: 'https://drive.google.com/file/d/1N-EVOCfXTnSlhItF_C67CIVBL1eGjjV9/view',
    directLinkFormat: 'pdf',
    image: ManualRedacaoPreview,
    imageFullSize: true,
  },
  {
    name: 'Versão principal',
    versions: [
      {
        name: 'normal',
        links: [
          { type: 'png', link: '/downloads/CredPago/CredPago-Normal.png' },
          { type: 'svg', link: '/downloads/CredPago/CredPago-Normal.svg' },
        ],
      },
      {
        name: 'com contorno',
        darkBackground: true,
        links: [
          { type: 'png', link: '/downloads/CredPago/CredPago-Contorno.png' },
          { type: 'svg', link: '/downloads/CredPago/CredPago-Contorno.svg' },
        ],
      },
      {
        name: 'para fundo escuro',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/CredPago/CredPago-Fundo-Escuro.png',
          },
          {
            type: 'svg',
            link: '/downloads/CredPago/CredPago-Fundo-Escuro.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/CredPago/CredPago-Monocromatico-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/CredPago/CredPago-Monocromatico-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          { type: 'png', link: '/downloads/CredPago/CredPago-Branco.png' },
          { type: 'svg', link: '/downloads/CredPago/CredPago-Branco.svg' },
        ],
      },
    ],
  },
  {
    name: 'CredPago + marca parceira',
    versions: [
      {
        name: 'normal',
        links: [
          {
            type: 'png',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Normal.png',
          },
          {
            type: 'svg',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Normal.svg',
          },
        ],
      },
      {
        name: 'para fundo escuro',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Fundo-Escuro.png',
          },
          {
            type: 'svg',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Fundo-Escuro.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Monocromatico-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Monocromatico-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Branco.png',
          },
          {
            type: 'svg',
            link: '/downloads/CredPago-Parceiro/CredPago-Parceiro-Branco.svg',
          },
        ],
      },
    ],
  },
  {
    name: 'Símbolo GO',
    versions: [
      {
        name: 'normal',
        links: [
          { type: 'png', link: '/downloads/GO/GO-Normal.png' },
          { type: 'svg', link: '/downloads/GO/GO-Normal.svg' },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          { type: 'png', link: '/downloads/GO/GO-Monocromatico-Azul.png' },
          { type: 'svg', link: '/downloads/GO/GO-Monocromatico-Azul.svg' },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          { type: 'png', link: '/downloads/GO/GO-Branco.png' },
          { type: 'svg', link: '/downloads/GO/GO-Branco.svg' },
        ],
      },
    ],
  },
  {
    name: 'GO + CredPago',
    versions: [
      {
        name: 'normal',
        links: [
          {
            type: 'png',
            link: '/downloads/GO-CredPago/GO-CredPago-Normal.png',
          },
          {
            type: 'svg',
            link: '/downloads/GO-CredPago/GO-CredPago-Normal.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/GO-CredPago/GO-CredPago-Monocromatico-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/GO-CredPago/GO-CredPago-Monocromatico-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/GO-CredPago/GO-CredPago-Branco.png',
          },
          {
            type: 'svg',
            link: '/downloads/GO-CredPago/GO-CredPago-Branco.svg',
          },
        ],
      },
    ],
  },
  {
    name: 'CredSign',
    versions: [
      {
        name: 'normal',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/CredSign/CredSign-Normal.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/CredSign/CredSign-Normal.svg',
          },
        ],
      },
      {
        name: 'com contorno',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/CredSign/CredSign-Contorno.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/CredSign/CredSign-Contorno.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/CredSign/CredSign-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/CredSign/CredSign-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/CredSign/CredSign-Branco.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/CredSign/CredSign-Branco.svg',
          },
        ],
      },
    ],
  },
  {
    name: 'Análise Instantânea',
    versions: [
      {
        name: 'normal',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Normal.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Normal.svg',
          },
        ],
      },
      {
        name: 'com contorno',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Contorno.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Contorno.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Branco.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Analise-Instantanea/Analise-Branco.svg',
          },
        ],
      },
    ],
  },
  {
    name: 'Multipagamento',
    versions: [
      {
        name: 'normal',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Normal.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Normal.svg',
          },
        ],
      },
      {
        name: 'com contorno',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Contorno.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Contorno.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Branco.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Multipagamento/Multipagamento-Branco.svg',
          },
        ],
      },
    ],
  },
  {
    name: 'Aluguel Sem Fiador',
    versions: [
      {
        name: 'normal',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Normal.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Normal.svg',
          },
        ],
      },
      {
        name: 'com contorno',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Contorno.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Contorno.svg',
          },
        ],
      },
      {
        name: 'monocromática azul',
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Azul.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Azul.svg',
          },
        ],
      },
      {
        name: 'monocromática branca',
        darkBackground: true,
        links: [
          {
            type: 'png',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Branco.png',
          },
          {
            type: 'svg',
            link: '/downloads/Servicos/Aluguel-sem-Fiador/Aluguel-Fiador-Branco.svg',
          },
        ],
      },
    ],
  },
  {
    name: 'Grafismos Institucionais',
    imageFullSize: true,
    versions: [
      {
        name: 'prédios',
        links: [
          { type: 'png', link: '/downloads/Predios/Predios.png' },
          { type: 'svg', link: '/downloads/Predios/Predios.svg' },
        ],
      },
      {
        name: 'linhas',
        links: [
          { type: 'png', link: '/downloads/Linhas/Linhas.png' },
          { type: 'svg', link: '/downloads/Linhas/Linhas.svg' },
        ],
      },
      {
        name: 'pattern',
        links: [
          { type: 'png', link: '/downloads/Pattern/Pattern-azul.png' },
          { type: 'svg', link: '/downloads/Pattern/Pattern-azul.svg' },
        ],
      },
    ],
  },
];

const DownloadPage = () => (
  <Layout internal={true} displayImobsParceiras={false} style={{ background: '#fbfbfb' }}>
    <SEO title="Downloads" />

    <UI.Row gutter={1}>
      <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
        <div style={{ textAlign: 'center', padding: '4em 0' }}>
          <UI.Title level={3} fontWeight="bold">
            Identidade CredPago
          </UI.Title>
          <UI.Title
            level={6}
            style={{
              color: '#7D7D7D',
              maxWidth: '600px',
              margin: '.5em auto 0',
            }}
          >
            Encontre abaixo para download nossos guias de marca e redação, assim como nossas marcas em diferentes
            formatos
          </UI.Title>
        </div>

        <DownloadsWrapper>
          {downloads.map((item, index) => {
            return (
              <DownloadItem
                key={index}
                name={item.name}
                image={item.image}
                versions={item.versions}
                directLink={item.directLink}
                directLinkFormat={item.directLinkFormat}
                imageFullSize={item.imageFullSize}
              />
            );
          })}
        </DownloadsWrapper>
      </UI.Col>
    </UI.Row>
  </Layout>
);

export default DownloadPage;
