import React, { useState } from 'react';

import UI from '#front-credpago';

import Pdf from '../../images/svg/filetypes/pdf.svg';
import Png from '../../images/svg/filetypes/png.svg';
import Svg from '../../images/svg/filetypes/svg.svg';
import { DownloadIconsWrapper, DownloadItem as DownloadWrapper, VersionsWrapper } from './styles';

const DownloadIcon = ({ type, link }) => {
  const types = {
    pdf: Pdf,
    svg: Svg,
    png: Png,
  };

  const File = types[type];

  return (
    <li>
      <a href={link} title={`Baixar arquivo ${type}`} target="_blank" rel="noreferrer">
        <img src={File} />
      </a>
    </li>
  );
};
const DownloadItem = ({ name, image, imageFullSize = false, versions, directLink, directLinkFormat }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const actualMappedItem = versions ? versions[currentTab] : null;
  const actualImage = image || versions[currentTab].links.find((item) => item.type == 'png').link;
  const isDark = Boolean(versions && versions[currentTab].darkBackground);

  return (
    <DownloadWrapper imageFullSize={imageFullSize} isDark={image && versions ? false : isDark}>
      <div className="image">
        <div className="image-container">
          <img src={actualImage} alt={name} />
        </div>
      </div>
      <div className="details">
        <UI.Title level={4} fontWeight="bold">
          {name}
        </UI.Title>
        <UI.Title level={6} style={{ color: '#7D7D7D', marginTop: '.4em' }}>
          Faça o download abaixo nos formatos:
        </UI.Title>
        <DownloadIconsWrapper>
          {directLink && directLinkFormat ? (
            <DownloadIcon type={directLinkFormat} link={directLink} />
          ) : (
            Object.keys(actualMappedItem.links).map((key) => {
              const item = actualMappedItem.links[key];
              return <DownloadIcon key={key} {...item} />;
            })
          )}
        </DownloadIconsWrapper>
      </div>
      <div className="version">
        {versions && (
          <>
            <p>Selecione a versão desejada</p>
            <VersionsWrapper>
              {versions.map((item, index) => {
                const isActive = currentTab === index;
                return (
                  <UI.Button onClick={() => setCurrentTab(index)} type={isActive ? 'primary' : 'secondary'} key={index}>
                    {item.name}
                  </UI.Button>
                );
              })}
            </VersionsWrapper>
          </>
        )}
      </div>
    </DownloadWrapper>
  );
};

export default DownloadItem;
