import styled from 'styled-components';

import UI from '#front-credpago';

import globals from '../globals';

const { containerMaxWidth, paddingSides } = globals;

export const DownloadItem = styled.div`
  border-radius: 20px;
  background: white;
  border: 2px solid #ececec;
  padding: 2em;
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 2em 2.5em;
  grid-template-areas:
    'image details'
    'version version';

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    padding: 1.5em;
    grid-gap: 1em;
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'details'
      'version';
  }

  .image {
    grid-area: image;

    .image-container {
      border-radius: 20px;
      border: 2px solid #ececec;
      padding: ${(props) => (props.imageFullSize ? '0' : '1.3em')};
      height: 320px;
      position: relative;
      display: flex;
      background: ${(props) => (props.isDark ? UI.globals.colors.brandDarkBlue : 'inherit')};

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        height: 240px;
      }

      img {
        align-self: center;
        margin: 0 auto;
        width: ${(props) => (props.imageFullSize ? '100%' : '50%')};
        height: ${(props) => (props.imageFullSize ? '100%' : '50%')};
        display: block;
        object-fit: scale-down;
      }
    }
  }
  .details {
    grid-area: details;
    align-self: center;
  }
  .version {
    grid-area: version;

    p {
      font-weight: bold;
      color: ${UI.globals.colors.brandDarkBlue};
    }
  }
`;

export const DownloadsWrapper = styled.section`
  max-width: ${containerMaxWidth};
  margin: 0 auto 3em;
  padding: 0 ${paddingSides};
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.4em;
`;

export const VersionsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    gap: 0.5rem;
    grid-auto-flow: inherit;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
  }
`;

export const DownloadIconsWrapper = styled.div`
  display: flex;
  list-style: none;
  margin: 1.5em 0 0 0;
  padding: 0;

  li {
    & + li {
      margin-left: 1.5em;
    }

    img {
      width: 47px;
      height: auto;
      display: block;
    }
  }
`;
